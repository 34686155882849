<template>
  <div class="spinner text-center">
    <ProgressSpinner />
    <h4>{{$t('global.loggingOut')}}...</h4>
  </div>
</template>

<script>
export default {
  name: "logout",
  mounted() {
    this.$keycloak.logout({
      redirectUri: process.env.VUE_APP_LOGOUT_URL,
    });
  },
};
</script>
